<template>
  <div>
    <div class="page-title">来源厂家管理</div>
    <!-- 选项 -->
    <div style="margin-top:1rem;float:left;width:100%">
      <el-input
        placeholder="搜索关键字"
        clearable
        v-model="keywords"
        style="width:240px"
        @keydown.native.enter="DoSearch"
      ></el-input>
      <el-button type="primary" plain @click="DoSearch">搜索</el-button>
      <el-button type="success" plain @click="AddSF" style="margin-left:1rem">添加来源厂家</el-button>
    </div>
    <!-- 表格 -->
    <div style="margin-top:1rem;float:left;width:100%">
      <pl-table
        :data="tableData"
      >
        <pl-table-column label="来源厂家" prop="来源厂家"></pl-table-column>
        <pl-table-column label="操作">
          <template slot-scope="scope">
            <el-button plain size="mini" @click="EditSF(scope.row)">修改</el-button>
            <el-button type="danger" size="mini" @click="DelSF(scope.row)">删除</el-button>
          </template>
        </pl-table-column>
      </pl-table>
    </div>
    <div style="float:left;width:100%;margin-top:1rem">
      <el-pagination
        @current-change="PageChg"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="count">
      </el-pagination>
    </div>
    <el-dialog
      title="新建来源厂家"
      :visible.sync="addLayer"
      width="400px"
      :close-on-click-modal="false"
    >
      <span>
        <el-input
          placeholder="请输入来源厂家名"
          clearable
          v-model="来源厂家"
        ></el-input>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addLayer = false">取 消</el-button>
        <el-button type="primary" @click="AddDo">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改来源厂家"
      :visible.sync="editLayer"
      width="400px"
      :close-on-click-modal="false"
    >
      <span>
        <el-input
          placeholder="请输入来源厂家名"
          clearable
          v-model="editRow.来源厂家"
        ></el-input>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editLayer = false">取 消</el-button>
        <el-button type="primary" @click="EditDo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {addSF, delSF, editSF, getSourceList} from "../http/api";

export default {
  name: "Source",
  data() {
    return {
      keywords: '',
      page: 1,
      pageSize: 100,
      count: 0,
      token: this.$cookies.get('token'),
      tableData: [],
      addLayer: false,
      来源厂家: '',
      editLayer: false,
      editRow: {},
    }
  },
  methods: {
    DoSearch() {
      getSourceList({
        token: this.token,
        page: this.page - 1,
        keywords: this.keywords,
      })
        .then(res => {
          // console.log(res)
          this.tableData = res.data.rs
          this.page = res.data.page + 1
          this.pageSize = res.data.pageSize
          this.count = res.data.count
        })
        .catch(err => console.log(err))
    },
    EditSF(row) {
      this.editRow = JSON.parse(JSON.stringify(row))
      this.editLayer = true
    },
    EditDo() {
      editSF({
        token: this.token,
        editRow: this.editRow,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.DoSearch()
            this.editRow = {}
            this.editLayer = false
            this.$message({
              type: 'success',
              message: '修改成功'
            })
          }
        })
        .catch(err => console.log(err))
    },
    DelSF(row) {
      this.$confirm(`确认删除 ${row.来源厂家} 吗`)
        .then(res => {
          delSF({
            token: this.token,
            id: row.id,
          })
            .then(res => {
              if (res.data.code === 'OK') {
                this.DoSearch()
                this.$message({
                  type: 'success',
                  message: '删除成功'
                })
              }
            })
            .catch(err => console.log(err))
        })
        .catch(err => console.log(err))
    },
    AddSF() {
      this.addLayer = true
    },
    AddDo() {
      addSF({
        token: this.token,
        来源厂家: this.来源厂家,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.DoSearch()
            this.来源厂家 = ''
            this.addLayer = false
            this.$message({
              type: 'success',
              message: '添加成功'
            })
          }
        })
        .catch(err => console.log(err))
    },
    PageChg(e) {
      this.DoSearch()
    }
  },
  activated() {
    this.DoSearch()
  }
}
</script>

<style scoped>

</style>
